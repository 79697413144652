import React from "react";

export default function GridSection() {
  const [showall, setshowall] = React.useState(false);
  return (
    <div className="homeContentBox">
      <h2 className="homeContentTitle darkerGreen">Ayrıcalıklarımız</h2>
      <div data-showall={showall} className="gridLayout">
        {/** New Box */}
        <div className="gridRow">
          <div className="gridBox large">
            <img
              alt="Kampüs Kreş Çiftliği"
              src="https://api.kampuskres.com.tr/images/cdnimage/ciftlik_repres.webp"
            />
            <div className="textBox">
              <h3>Kampüs Kreş Çiftliği</h3>
              <p>
                Kampüs Kreş'te 650 metrekarelik bir hayvanat bahçesi
                bulunmaktadır. Bu alanda keçiler, tavus kuşları, hindiler,
                kaplumbağalar, ördekler, tavuklar ve horozlar barınmaktadır.
                Kampüs Kreş Çiftliği çocukların doğayla iç içe vakit geçirmesine
                olanak sağlamaktadır.
              </p>
            </div>
          </div>
          <div id="revir" className="gridBox sm">
            <img
              alt="Revir"
              src="https://api.kampuskres.com.tr/images/cdnimage/nurse_repres.webp"
            />
            <div className="textBox">
              <h3>Revir</h3>
            </div>
          </div>
        </div>
        {/** New Box */}
        <div className="gridRow">
          <div id="ciftdil" className="gridBox large">
            <img
              alt="Çift Dilli Eğitim"
              src="https://api.kampuskres.com.tr/images/cdnimage/multi_lang_repres.webp"
            />
            <div className="textBox">
              <h3>Çift Dilli Eğitim </h3>
              <p>
                Okulumuzun çift dilli eğitim anlayışı, çocuklarımızın global bir
                dünyaya hazırlanmasını sağlarken, onların bilişsel ve sosyal
                gelişimlerine de büyük katkı sunar.
              </p>
            </div>
          </div>
          <div className="gridBox sm">
            <img
              alt="Kampüs Kreş Ormanları"
              src="https://api.kampuskres.com.tr/images/cdnimage/forest_repres.webp"
            />
            <div className="textBox">
              <h3>Çocukların Ağaçlarıyla Kampüs Kreş Ormanları</h3>
            </div>
          </div>
        </div>
        {/** New Box */}
        <div className="gridRow">
          <div id="yazokulu" className="gridBox large">
            <img
              alt="Yaz Okulu"
              src="https://api.kampuskres.com.tr/images/cdnimage/summer_school_repres.webp"
            />
            <div className="textBox">
              <h3>Kendi Bahçemizde Yaz Okulu </h3>
              <p>
                Kampüs Kreş'te yaz okulu 2.859 metrekarelik geniş bir çim
                bahçede, çocukların keyifle vakit geçirebileceği 3 kulvarlı bir
                yüzme havuzunda yapılmakta ve çocuklara hem eğlenceli hem de
                verimli bir tatil süreci sunmaktadır.
              </p>
            </div>
          </div>
          <div className="gridBox sm">
            <img
              alt="Açık Alan"
              src="https://api.kampuskres.com.tr/images/image/7923-8439-1557-9452.webp"
            />
            <div className="textBox">
              <h3>3000 m2 Açık Alan</h3>
            </div>
          </div>
        </div>
        {/** New Box */}
        <div className="gridRow">
          <div className="gridBox large">
            <img
              id="beslenme"
              alt="Beslenme"
              src="https://api.kampuskres.com.tr/images/cdnimage/food_repres.webp"
            />
            <div className="textBox">
              <h3>Ev Sıcaklığında Beslenme</h3>
              <p>
                Yemeklerimiz, profesyonel bir ekip tarafından mutfağımızda
                pişirilmektedir. Yemeklerimiz, porselen tabaklar ve ev
                sıcaklığındaki sunumlarla çocuklara servis edilmektedir.
              </p>
            </div>
          </div>
          <div className="gridBox sm">
            <img
              alt="Kapalı Alan"
              src="https://api.kampuskres.com.tr/images/cdnimage/closed_area_repres.webp"
            />
            <div className="textBox">
              <h3>5000 m2 Kapalı Alan</h3>
            </div>
          </div>
        </div>
      </div>
      <button
        data-hide={showall}
        onClick={() => setshowall(true)}
        className="showMoreButton"
      >
        Daha Fazla Göster
      </button>
    </div>
  );
}
