import { Routes, Route, useLocation, Navigate } from "react-router-dom";
// import { RegistryFormComponent } from "./pages/form";
import { AdminRoutes } from "./pages/admin";
import { NavBar } from "./components/NavBar";
import { Footer } from "./components/Footer";
import { LoginPage } from "./pages/admin/Login";
import { Home } from "./pages/home";
import { AydinlatmaMetni, AcikRiza } from "./pages/documents";
import { NotFound } from "./components/NotFound";
import { KVKK } from "./pages/kvkk/index";
import { importAll } from "./tools/importAll";

import "./App.css";
import { BirthDayPage } from "./pages/birthday";
import { useState, Fragment } from "react";
import { RegistryFormPage } from "./pages/newForm";
import AdPage from "./pages/ad";
import ContactPage from "./pages/contact/contact";
import FormSuccessPage from "./pages/ad/components/formSuccess";
import InfoFormPage from "./pages/infoForm/form";
import NotReadyYet from "./pages/en/notReady";

function App() {
  const [hideNavigationElements, setHideNavigationElements] = useState(false);

  const subViews = importAll(
    require.context("./pages/subViews", true, /\.parentPage.(js|jsx)$/)
  );

  let search = useLocation().search;

  return (
    <Fragment>
      {!hideNavigationElements && <NavBar />}
      <Routes>
        <Route path="/lp" element={<AdPage />} />
        <Route path="/lp/success" element={<FormSuccessPage />} />
        <Route path="/iletisim" element={<ContactPage />} />
        <Route path="/bilgi-talep-formu" element={<InfoFormPage />} />
        <Route path="/en" element={<NotReadyYet />} />
        <Route
          path="/ogrencibasvuru"
          element={<Navigate to="/bilgi-talep-formu" />}
        />
        <Route
          path="/ogrencibasvuru-2025-2026"
          element={<Navigate to="/bilgi-talep-formu" />}
        />
        <Route
          path="/ogrencibasvuru-2025-2026-2"
          element={<Navigate to="/bilgi-talep-formu" />}
        />
        <Route path={`/admin/login`} element={<LoginPage params={search} />} />
        <Route path="/" exact element={<Home />} />
        <Route
          path="/documents/aydinlatma-metni"
          element={<AydinlatmaMetni />}
        />
        <Route path="/documents/acik-riza" element={<AcikRiza />} />
        <Route path="/kvkk" element={<KVKK />} />
        <Route
          path="/mesaj"
          element={
            <BirthDayPage
              params={search}
              hideNavigation={setHideNavigationElements}
            />
          }
        />
        {AdminRoutes().map((e) => e)}
        {Object.keys(subViews).map((key, i) => {
          let view = subViews[key].default;
          let Element = view[1];
          return (
            <Route key={i} path={`/${view[0].pathName}`}>
              <Route path={`/${view[0].pathName}`} element={<Element />} />
              {view[2]().map((e) => e)}
            </Route>
          );
        })}
        <Route
          path="*"
          element={<NotFound title="Aradığınız Sayfa Bulunamadı" />}
        />
      </Routes>
      {!hideNavigationElements && <Footer />}
    </Fragment>
  );
}

export default App;
