export default function FormSuccessPage() {
  return (
    <div
      style={{
        alignSelf: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "20px",
      }}
      className="formSuccess"
    >
      <img
        className="welly-image"
        style={{ animation: "none" }}
        srcSet="https://api.kampuskres.com.tr/images/cdnimage/welly-up-portrait_1x.webp 1x, https://api.kampuskres.com.tr/images/cdnimage/welly-up-portrait_2x.webp 2x, https://api.kampuskres.com.tr/images/cdnimage/welly-up-portrait_3x.webp 3x"
        alt="welly the tiger"
      />
      <div className="text-wrap">
        <h1>Formunuz Bize Ulaştı</h1>
        <p>En kısa zamanda sizinle iletişime geçeceğiz.</p>
      </div>
    </div>
  );
}
