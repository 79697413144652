import React from "react";
import { useLocation, Route, Navigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { checkSession } from "../../graphql/queries";

import { FormsPage } from "./Forms";
import { AdminPage } from "./Admin";
import { EditFormPage } from "./EditForm/";
import { AdminGallery } from "./Gallery";
import { AdminAnnouncements } from "./Announcements";
import { SpinnerIcon } from "../../assets/icons";
import { AdminInfoFormsPage } from "./InfoForms";

let prefix = "admin";

export function AdminRoutes() {
  let search = new URLSearchParams(useLocation().search);
  let pathname = useLocation().pathname;
  let searchRaw = useLocation().search;

  let session = window.localStorage.getItem("session");
  const [getAdmin, { loading, data, called }] = useLazyQuery(checkSession);
  if (session && !called)
    getAdmin({ variables: { session: decodeURIComponent(session) } });

  if (loading)
    return [
      <Route
        key={21}
        path={`${prefix}/*`}
        element={
          <div
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              top: "35%",
              left: "50%",
            }}
            className="loading"
          >
            <SpinnerIcon />
            <p>Sayfa Yükleniyor</p>
          </div>
        }
      />,
    ];

  if (called && data?.checkSession?.isAdmin)
    return [
      <Route
        key={6}
        path={`/${prefix}`}
        element={
          <AdminPage
            adminType={data.checkSession.adminType}
            params={searchRaw}
          />
        }
      />,
      <Route
        key={7}
        path={`/${prefix}/forms`}
        element={
          <FormsPage adminType={data.checkSession.adminType} params={search} />
        }
      />,
      <Route
        key={8}
        path={`/${prefix}/gallery`}
        element={
          data.checkSession.adminType === "KURUMSAL" ||
          data.checkSession.adminType === "ALP" ? (
            <AdminGallery adminType={data.checkSession.adminType} />
          ) : (
            <Navigate to="/admin" />
          )
        }
      />,
      <Route
        key={9}
        path={`/${prefix}/announcements`}
        element={
          data.checkSession.adminType === "KURUMSAL" ||
          data.checkSession.adminType === "ALP" ? (
            <AdminAnnouncements params={search} />
          ) : (
            <Navigate to="/admin" />
          )
        }
      />,
      <Route
        key={10}
        path={`/${prefix}/form/edit`}
        element={
          data.checkSession.adminType === "ALP" ? (
            <EditFormPage />
          ) : (
            <Navigate to="/admin" />
          )
        }
      />,
      <Route
        key={11}
        path={`/${prefix}/infoForms`}
        element={
          <AdminInfoFormsPage
            adminType={data.checkSession.adminType}
            params={search}
          />
        }
      />,
    ];
  return [
    [
      `/${prefix}`,
      `/${prefix}/forms`,
      `/${prefix}/form/edit`,
      `/${prefix}/gallery`,
      `/${prefix}/announcements`,
      `/${prefix}/infoForms`,
    ].map((path, i) => {
      return (
        <Route
          key={i + 11}
          path={path}
          element={
            <Navigate
              to={`/admin/login${
                pathname !== "/admin" && !pathname.includes("/login")
                  ? `?next=${pathname.replace(
                      "/admin",
                      ""
                    )}${encodeURIComponent(searchRaw)}`
                  : ""
              }`}
            />
          }
        />
      );
    }),
  ];
}
