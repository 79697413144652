import React, { useEffect } from "react";
import CountryCodeSelector from "./CountryCodeSelector";

import "./styles.infoform.css";
import { useMutation } from "@apollo/client";
import { createInfoForm } from "../../../graphql/mutations";

import { ReactComponent as CheckEnvelope } from "../../../assets/icons/envelope-circle-check-solid.svg";
import { ReactComponent as XMark } from "../../../assets/icons/triangle-exclamation-solid.svg";

import { AydinlatmaMetni } from "../../documents/index";
import { IYSMetni } from "../../documents/iys";
import { CheckBoxInputInfo } from "./CheckBoxInputInfo";

export default function InfoForm({
  backgroundColor,
  buttonColor,
  status,
  setStatus,
  ...props
}) {
  const [createForm] = useMutation(createInfoForm, {
    onCompleted: (_) => {
      setStatus({ error: false, success: true, description: null });
    },
    onError: (error) => {
      setStatus({
        error: true,
        success: false,
        description: error.graphQLErrors[0],
      });
    },
  });

  const [countryCode, setCountryCode] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [district, setDistrict] = React.useState("");
  const [adConsent, setAdConsent] = React.useState(false);
  const [allowedLegal, setAllowedLegal] = React.useState(false);

  const [showErrors, setShowErrors] = React.useState(false);

  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAtCallback = function (index, char) {
    let a = this.split("");
    a[index] = char(a[index]);
    return a.join("");
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAt = function (index, char) {
    let a = this.split("");
    a[index] = char;
    return a.join("");
  };

  function handlePhoneNumberChange(event) {
    let value = event.target.value;

    // remove characters that arent digits and parantheses
    value = value.replace(/((?!\(|\)|\d).)/g, "");

    let valueCharArray = value.split("");

    // remove unwanted parantheses
    for (let i = 0; i < valueCharArray.length; i++) {
      if (valueCharArray[i] === "(" && i !== 0) {
        value = value.replaceAt(i, "");
        // update char array
        valueCharArray = value.split("");
      }
      if (valueCharArray[0] === "(" && valueCharArray[i] === ")" && i !== 4) {
        value = value.replaceAt(i, "");
        // update char array
        valueCharArray = value.split("");
      }
    }

    // remove both parantheses if one of them is removed
    if (!valueCharArray.includes(")") && valueCharArray.includes("(")) {
      event.target.value = value;
      return setPhoneNumber(value.replace("(", ""));
    }
    if (valueCharArray.includes(")") && !valueCharArray.includes("(")) {
      event.target.value = value;
      return setPhoneNumber(value.replace(")", ""));
    }

    // add parantheses
    if (!valueCharArray.includes(")")) {
      if (value.length >= 3 && !valueCharArray.includes("(")) {
        let valueWithGuides = value.replaceAtCallback(0, (matched) => {
          return `${matched}{$}`;
        });
        let substring = valueWithGuides.substring(0, 6);
        let prettified = valueWithGuides.replace(substring, (matched) => {
          return `(${matched.replace("{$}", "")})`;
        });

        value = prettified;
        valueCharArray = value.split("");
        event.target.value = value;
        return setPhoneNumber(prettified);
      }
    }

    if (valueCharArray.includes("(") && valueCharArray.includes(")")) {
      // first space
      if (valueCharArray[5] !== " " && valueCharArray[5]) {
        value = value.replaceAtCallback(5, (matched) => {
          return ` ${matched}`;
        });
        valueCharArray = value.split("");
      }

      // second space
      if (valueCharArray[9] !== " " && valueCharArray[9]) {
        value = value.replaceAtCallback(9, (matched) => {
          return ` ${matched}`;
        });
        valueCharArray = value.split("");
      }

      // third space
      if (valueCharArray[12] !== " " && valueCharArray[12]) {
        value = value.replaceAtCallback(12, (matched) => {
          return ` ${matched}`;
        });
        valueCharArray = value.split("");
      }
    }

    // change the last digit (14th) of the number with the last input
    if (valueCharArray.length > 15) value = value.replaceAt(14, "");

    event.target.value = value;
    return setPhoneNumber(value);
  }

  function submitForm(e) {
    e.preventDefault();
    if (
      name &&
      surname &&
      phoneNumber &&
      countryCode &&
      district &&
      email &&
      allowedLegal &&
      adConsent
    ) {
      createForm({
        variables: {
          _formVersion: 0,
          fullName: `${name} ${surname}`,
          telephone: `${countryCode} ${phoneNumber}`,
          district,
          email,
        },
      });
    } else {
      setShowErrors(true);
    }
  }

  useEffect(() => {
    if (status.success) {
      window.location.href = "/lp/success";
    }
  }, [status]);

  if (status.success)
    return (
      <div
        {...props}
        className={`${
          props.className ? props.className : "infoForm"
        } result success`}
      >
        <CheckEnvelope />
        <h3>
          {props.english
            ? "Your Form has been Delivered"
            : "Formunuz Başarıyla Gönderildi"}
        </h3>
        <p>
          {props.english
            ? "We will reach out to you as soon as possible."
            : "Size en kısa zamanda geri dönüş sağlayacağız."}
        </p>
      </div>
    );

  if (status.error)
    return (
      <div
        {...props}
        className={`${
          props.className ? props.className : "infoForm"
        } result error`}
      >
        <XMark />
        <h3>
          {props.english
            ? "An Error Occured While Sending Your Form"
            : "Formunuz Gönderilirken Bir Sorun Oluştu"}
        </h3>
        <p>
          {status.description.extensions.code === 409
            ? "Bilgi talep formu önceden doldurulmuş."
            : "Lütfen tekrar deneyiniz."}
        </p>
        <button
          onClick={() => {
            window.location.reload();
          }}
        >
          {props.english ? "Fill Again" : "Tekrar Doldur"}
        </button>
      </div>
    );

  return (
    <form className="infoForm" {...props}>
      <h3>
        {props.english ? "Information Request Form" : "Bilgi Talep Formu"}
      </h3>
      <div className="rowInputGroup">
        <input
          data-error={showErrors && name.trim() === ""}
          placeholder={props.english ? "Name" : "Ad"}
          type="text"
          name="name"
          id="name"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <input
          data-error={showErrors && surname.trim() === ""}
          placeholder={props.english ? "Surname" : "Soyad"}
          type="text"
          name="surname"
          id="surname"
          onChange={(e) => setSurname(e.target.value)}
          value={surname}
        />
      </div>
      <div className="phoneInput">
        <CountryCodeSelector
          label={props.english ? "Country Code" : "Ülke Kodu"}
          data-error={showErrors && countryCode === ""}
          className="countryCodeInput"
          onChange={(e) => {
            console.log(e.target.value);
            setCountryCode(e.target.value);
          }}
          value={countryCode}
        />
        <input
          data-error={showErrors && phoneNumber.trim() === ""}
          placeholder={props.english ? "Telephone Number" : "Telefon Numarası"}
          type="tel"
          name="phonenumber"
          id="phonenumber"
          onChange={handlePhoneNumberChange}
          value={phoneNumber}
        />
      </div>
      <input
        data-error={showErrors && email.trim() === ""}
        placeholder="Email"
        type="email"
        name="email"
        id="email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <input
        data-error={showErrors && district.trim() === ""}
        placeholder={props.english ? "Your District" : "Yaşadığınız İlçe"}
        type="text"
        name="district"
        id="county"
        onChange={(e) => setDistrict(e.target.value)}
        value={district}
      />

      <CheckBoxInputInfo
        data-error={showErrors && !adConsent}
        id="legal_adConsent"
        label={
          props.english
            ? "Click to allow İleti Yönetim Sistemi (Message Management System)"
            : "İleti Yönetim Sistemi'ni onaylamak için tıklayınız."
        }
        popUpPage={IYSMetni}
        value={{ set: setAdConsent, get: adConsent }}
        required
      />
      <CheckBoxInputInfo
        data-error={showErrors && !allowedLegal}
        id="legal_consent"
        label={
          props.english
            ? "Click to allow KVKK Clarification and Explicit Consent document"
            : "KVKK Aydınlatma ve Açık Rıza metnini okumak ve onaylamak için tıklayınız."
        }
        popUpPage={AydinlatmaMetni}
        value={{ set: setAllowedLegal, get: allowedLegal }}
        required
      />
      <button onClick={submitForm} className="sendButton" type="button">
        {props.english ? "Submit" : "Gönder"}
      </button>
    </form>
  );
}
