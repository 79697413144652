import React from "react";
import ContactSection from "../ad/components/ContactSection";
import { Helmet } from "react-helmet";

export default function ContactPage() {
  const [status, setStatus] = React.useState({
    error: false,
    success: false,
    description: null,
  });
  return (
    <React.Fragment>
      <Helmet>
        <title>Kampüs Kreş - İletişim</title>
        <meta name="title" content="Kampüs Kreş - İletişim" />
        <meta property="og:title" content="Kampüs Kreş - İletişim" />
        <meta name="twitter:title" content="Kampüs Kreş - İletişim" />
      </Helmet>
      <main
        style={{
          minHeight: "80svh",
          background: "#2b2365",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "20px",
        }}
      >
        <ContactSection
          className="homeContentBox blurple contactModify"
          hideTitle
          status={status}
          setStatus={setStatus}
        />
      </main>
    </React.Fragment>
  );
}
