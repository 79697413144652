import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { InstagramEmbed } from "react-social-media-embed";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PhoneFilledIcon,
  DocumentStrokeIcon,
  WhatsAppIcon,
} from "../../assets/icons/index.js";

import "./styles.ad.css";
import ReviewSection from "./components/ReviewSection.jsx";
import InfoForm from "./components/InfoForm.jsx";
import GallerySection from "./components/GallerySection.jsx";
import ContactSection from "./components/ContactSection.jsx";
import GridSection from "./components/GridSection.jsx";
import { Helmet } from "react-helmet";
// import { useMutation } from "@apollo/client";
// import { createAdVisit } from "../../graphql/mutations/index.js";

export default function AdPage() {
  // const [createVisit] = useMutation(createAdVisit);

  // const [searchParams] = useSearchParams();

  const [heroPage, setHeroPage] = useState(1);
  const [height, setHeight] = useState(0);
  const [scrollheight, setScrollHeight] = useState(0);

  // const [ap, sap] = useState(false);

  // function saveReferrerToDatabase(ref, docRef, rv) {
  //   createVisit({
  //     variables: {
  //       userAgent: navigator.userAgent || "Bulunamadı",
  //       referrer: `REF: ${ref || "Bulunamadı"}, DOC_REF: ${
  //         docRef || "Bulunamadı"
  //       }`,
  //       revisit: rv,
  //     },
  //   });
  // }

  // useEffect(() => {
  //   // Save referrer data to db
  //   const referrer = searchParams.get("r");

  //   const v_c = parseInt(window.localStorage.getItem("ad_v_c") || "0");

  //   if (v_c <= 1 && ap === false) {
  //     sap(true);
  //     if (referrer || document.referrer) {
  //       saveReferrerToDatabase(referrer, document.referrer, v_c === 1);
  //     }
  //   }

  //   sap(true);

  //   window.localStorage.setItem("ad_v_c", v_c + 1);
  // }, []);

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      changePage(true);
    }, 3000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [heroPage]);

  function changePage(fwd) {
    if (fwd) {
      setHeroPage((cur) => {
        if (cur === 4) return 1;
        return cur + 1;
      });
    } else {
      setHeroPage((cur) => {
        if (cur === 1) return 4;
        return cur - 1;
      });
    }
  }

  const [formStatusSync, setFormStatusSync] = useState({
    error: false,
    success: false,
    description: null,
  });

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollHeight(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    var body = document.body,
      html = document.documentElement;

    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    setHeight(height);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main>
      <Helmet>
        <title>Kampüs Kreş - Hayallerinizin Çok Ötesindeki Kreş</title>
        <meta
          name="title"
          content="Kampüs Kreş - Hayallerinizin Çok Ötesindeki Kreş"
        />
        <meta
          property="og:title"
          content="Kampüs Kreş - Hayallerinizin Çok Ötesindeki Kreş"
        />
        <meta
          name="twitter:title"
          content="Kampüs Kreş - Hayallerinizin Çok Ötesindeki Kreş"
        />
      </Helmet>
      <div
        className={`floatingActions ${
          height - scrollheight > 200 && scrollheight > 600 ? "show" : "hide"
        }`}
      >
        <a className="floatButton lg" href="#form">
          <DocumentStrokeIcon />
          <span>Bilgi Talep Formu</span>
        </a>
        <a className="floatButton sm" href="tel:+90 216 420 75 75">
          <PhoneFilledIcon />
        </a>
        <a
          className="floatButton sm wp"
          href="https://wa.me/902164207575?text=Merhaba"
        >
          <WhatsAppIcon />
        </a>
      </div>
      <div className="hero">
        <div className="slider-hero">
          <img
            className="welly-image"
            srcSet="https://api.kampuskres.com.tr/images/cdnimage/welly-up-portrait_1x.webp 1x, https://api.kampuskres.com.tr/images/cdnimage/welly-up-portrait_2x.webp 2x, https://api.kampuskres.com.tr/images/cdnimage/welly-up-portrait_3x.webp 3x"
            alt="welly the tiger"
          />
          <div className="slider-text-wrap">
            <h1>Hayallerinizin Çok Ötesinde</h1>
            <p>
              Her çocuğun özgün gelişimini destekleyen güvenli ve eğlenceli bir
              eğitim ortamı
            </p>
          </div>
          <div className="slider-button-wrap">
            <button
              onClick={() => (window.location.href = "#form")}
              className="slider-button fill"
            >
              <DocumentStrokeIcon />
              <span>Bilgi Talep Formu</span>
            </button>
            <button
              onClick={() => (window.location.href = "tel:+90 216 420 75 75")}
              className="slider-button stroke"
            >
              <PhoneFilledIcon />
              <span>Telefon ile Ara</span>
            </button>
          </div>
        </div>
        <div className="slider">
          <div
            id="1"
            data-selected={heroPage === 1}
            className="slider-item"
          ></div>
          <div
            id="2"
            data-selected={heroPage === 2}
            className="slider-item"
          ></div>
          <div
            id="3"
            data-selected={heroPage === 3}
            className="slider-item"
          ></div>
          <div
            id="4"
            data-selected={heroPage === 4}
            className="slider-item"
          ></div>
        </div>
        <button
          onClick={() => changePage(false)}
          className="slider-pagination-button left"
        >
          <ChevronLeftIcon />
        </button>
        <button
          onClick={() => changePage(true)}
          className="slider-pagination-button right"
        >
          <ChevronRightIcon />
        </button>
        <div className="slider-pagination">
          <div
            id="1"
            data-selected={heroPage === 1}
            className="slider-pagination-dot"
          ></div>
          <div
            data-selected={heroPage === 2}
            className="slider-pagination-dot"
          ></div>
          <div
            data-selected={heroPage === 3}
            className="slider-pagination-dot"
          ></div>
          <div
            data-selected={heroPage === 4}
            className="slider-pagination-dot"
          ></div>
        </div>
      </div>

      <div id="form" className="homeContentBox">
        <h2 className="homeContentBoxTitle camelcase brand">Bizi Tanıyın</h2>
        <div className="wrapbox">
          <iframe
            className="youtubeBox"
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/TxcSQRkSx9k?si=onXFD6mjDPuTnrRX&amp;controls=0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
          <InfoForm status={formStatusSync} setStatus={setFormStatusSync} />
        </div>
      </div>

      <GridSection />

      {/* <div id="page-reviews" className="homeContentBox brand">
        <h2
          id="reviews-section_title_1"
          className="homeContentBoxTitle camelcase white"
        >
          Mutlu velilerimiz
        </h2>
        <iframe
          scrolling="no"
          className="googleReviews"
          title="google reviews"
          src="https://f53c9c3c739745ee97c8cc5d6e61bdb4.elf.site/"
        ></iframe>
      </div> */}

      <ReviewSection winWidth={window.innerWidth} />

      <GallerySection winWidth={window.innerWidth} />

      <div className="homeContentBox instagram-gradient">
        <h2 className="homeContentBoxTitle camelcase white">
          Instagram Paylaşımlarımız
        </h2>
        <div className="instagram-feed">
          <InstagramEmbed
            url="https://www.instagram.com/reel/DG20Nozgfxp/"
            width={328}
            height={464}
          />
          <InstagramEmbed
            url="https://www.instagram.com/reel/DGf72xhI0-N/"
            className="testing"
            width={328}
            height={464}
          />
          <InstagramEmbed
            url="https://www.instagram.com/reel/DDb7NPaAbSx/"
            width={328}
            height={464}
          />
          <InstagramEmbed
            url="https://www.instagram.com/reel/DENQcfaAOgr/"
            width={328}
            height={464}
          />
        </div>
      </div>

      <ContactSection status={formStatusSync} setStatus={setFormStatusSync} />
    </main>
  );
}
