import React from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { getInfoForms, getInfoForm } from "../../../graphql/queries";

import "./styles.forms.css";
import "../../../styles.formPage.css";

import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";

import { DownloadIcon, SpinnerIcon } from "../../../assets/icons";
import { NotFound } from "../../../components/NotFound";
import { Helmet } from "react-helmet";

export function AdminInfoFormsPage({ params }) {
  const { loading, data } = useQuery(getInfoForms, {
    variables: {
      session: window.localStorage.getItem("session"),
    },
  });
  let [
    getSingleForm,
    { loading: loadingForm, data: dataForm, called: calledSingleForm },
  ] = useLazyQuery(getInfoForm, {
    variables: {
      session: window.localStorage.getItem("session"),
    },
  });

  const [outputing, setOutputing] = React.useState(false);

  const downloadXlsxDocument = () => {
    try {
      const json = data.getInfoForms;

      let forms = json.map((form) => {
        return {
          _id: form._id,
          "Ad Soyad": form.fullName,
          "Telefon Numarasi": form.telephone,
          Email: form.email,
          Ilce: form.district,
        };
      });

      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(forms);

      XLSX.utils.book_append_sheet(wb, ws, `KampusKresFormlari`);

      XLSX.writeFile(wb, "KampusKresFormlari.xlsx");
    } catch (err) {
      console.log(err);
    }
  };

  const toEnglishCharset = function (text) {
    return text
      .replace(/ı/g, "i")
      .replace(/İ/g, "I")
      .replace(/ü/g, "ü")
      .replace(/Ü/g, "Ü")
      .replace(/ğ/g, "g")
      .replace(/Ğ/g, "G")
      .replace(/ş/g, "s")
      .replace(/Ş/g, "Ş")
      .replace(/ç/g, "c")
      .replace(/Ç/g, "C")
      .replace(/ö/g, "o")
      .replace(/Ö/g, "O");
  };

  if (loading || loadingForm)
    return (
      <div
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          top: "35%",
          left: "50%",
        }}
        className="loading"
      >
        <SpinnerIcon />
        <p>Sayfa Yükleniyor</p>
      </div>
    );

  const downloadPdfDocument = (e, rootElementId, formId, student) => {
    e.preventDefault();
    let doc = new jsPDF("portrait", "pt", "A4");
    doc.html(
      document.getElementById(rootElementId),
      {
        callback: () => {
          doc.save(`${student}_${formId}.pdf`);
        },
      },
      {
        language: "tr",
      }
    );
    setTimeout(() => {
      setOutputing(false);
    }, 500);
  };

  let formFind = params.get("form");
  if (formFind && !calledSingleForm)
    getSingleForm({
      variables: {
        _id: parseInt(formFind),
        session: window.localStorage.getItem("session"),
      },
    });
  if (calledSingleForm) {
    dataForm = dataForm.getInfoForm;

    if (dataForm === null) {
      return <NotFound title="Aradığınız Form Bulunamadı" />;
    }

    return (
      <div className="forms">
        <Helmet>
          <title>Kampüs Kreş - Admin</title>
          <meta name="title" content="Kampüs Kreş - Admin" />
          <meta property="og:title" content="Kampüs Kreş - Admin" />
          <meta name="twitter:title" content="Kampüs Kreş - Admin" />
        </Helmet>
        <div className="form">
          <div className="pdfOut" id="pdfOut">
            <div className="formHeading">
              <h1 className="formTitle">
                {outputing
                  ? toEnglishCharset(dataForm.fullName)
                  : dataForm.fullName}
              </h1>
              <span className="formId">Form ID: #{dataForm._id}</span>
            </div>
            <div className="formValueWrapper">
              <h3>{outputing ? toEnglishCharset("Telefon:") : "Telefon:"}</h3>
              <div className="formValue">
                {outputing
                  ? toEnglishCharset(dataForm.telephone)
                  : dataForm.telephone}
              </div>
            </div>
            <div className="formValueWrapper">
              <h3>{outputing ? toEnglishCharset("Email:") : "Email:"}</h3>
              <div className="formValue">{dataForm.email}</div>
            </div>
            <div className="formValueWrapper">
              <h3>{outputing ? toEnglishCharset("İlçe:") : "İlçe:"}</h3>
              <div className="formValue">
                {outputing
                  ? toEnglishCharset(dataForm.district)
                  : dataForm.district}
              </div>
            </div>
          </div>
          <div className="formButtonWrap">
            <div className="downloadButtonsWrap">
              <button
                onClick={(e) => {
                  setOutputing(true);
                  downloadPdfDocument(
                    e,
                    "pdfOut",
                    dataForm._id,
                    dataForm.fullName
                  );
                }}
                className="pdfIn"
              >
                <DownloadIcon />
                Formu PDF Olarak İndir
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="forms">
      <button
        style={data.getInfoForms.length === 0 ? { display: "none" } : {}}
        onClick={downloadXlsxDocument}
        className="modalOpenButtonXlsx"
      >
        Tüm Formları Excel Dosyasına Çıkart
      </button>
      {data.getInfoForms.length < 1 ? (
        <NotFound title="Görüntülenecek Form Yok" />
      ) : (
        data.getInfoForms.map((form, i) => {
          return (
            <div key={i} className="form">
              <div className="pdfOut" id="pdfOut">
                <div className="formHeading">
                  <h1 className="formTitle">
                    {outputing
                      ? toEnglishCharset(form.fullName)
                      : form.fullName}
                  </h1>
                  <span className="formId">Form ID: #{form._id}</span>
                </div>
                <div className="formValueWrapper">
                  <h3>
                    {outputing ? toEnglishCharset("Telefon:") : "Telefon:"}
                  </h3>
                  <div className="formValue">
                    {outputing
                      ? toEnglishCharset(form.telephone)
                      : form.telephone}
                  </div>
                </div>
                <div className="formValueWrapper">
                  <h3>{outputing ? toEnglishCharset("Email:") : "Email:"}</h3>
                  <div className="formValue">{form.email}</div>
                </div>
                <div className="formValueWrapper">
                  <h3>{outputing ? toEnglishCharset("İlçe:") : "İlçe:"}</h3>
                  <div className="formValue">
                    {outputing
                      ? toEnglishCharset(form.district)
                      : form.district}
                  </div>
                </div>
              </div>
              <div className="formButtonWrap">
                <div className="downloadButtonsWrap">
                  <button
                    onClick={(e) => {
                      setOutputing(true);
                      downloadPdfDocument(e, "pdfOut", form._id, form.fullName);
                    }}
                    className="pdfIn"
                  >
                    <DownloadIcon />
                    Formu PDF Olarak İndir
                  </button>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
