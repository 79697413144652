import { Map } from "../home/components/map";

import {
  PhoneFilledIcon,
  PinIcon,
  MailIcon,
} from "../../assets/icons/index.js";

import instagram from "../../assets/instagram.png";
import youtube from "../../assets/youtube.png";
import facebook from "../../assets/facebook.png";
import linkedin from "../../assets/linkedIn.png";
import React from "react";
import InfoForm from "../ad/components/InfoForm.jsx";
import { Helmet } from "react-helmet";

export default function NotReadyYet() {
  const [status, setStatus] = React.useState({
    error: false,
    success: false,
    description: null,
  });
  return (
    <main
      style={{
        minHeight: "80svh",
        background: "#2b2365",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "20px",
      }}
    >
      <div className="homeContentBox blurple">
        <h1 className="infoTitle">Far Beyond Your Dreams</h1>
        <div className="contactGroups">
          <div className="contactBigRowGroup">
            <Map />
            <InfoForm
              english
              data-english
              className="infoForm purple"
              status={status}
              setStatus={setStatus}
            />
          </div>
          <div className="contactRowWrap">
            <a href="tel:+90 216 420 75 75" className="contactItem">
              <PhoneFilledIcon />
              <span>+90 216 420 75 75</span>
            </a>
            <a href="mailto:info@kampuskres.com.tr" className="contactItem">
              <MailIcon />
              <span>info@kampuskres.com.tr</span>
            </a>
            <div className="contactItem">
              <PinIcon />
              <span>Dudullu OSB, 5. Cadde, No:6 Ümraniye / İSTANBUL</span>
            </div>
          </div>
          <div className="contactSocialWrap">
            <a
              className="contactSocial instagram"
              href="https://www.instagram.com/kampuskres/"
            >
              <img src={instagram} alt="Instagram Logo" />
              {/* <span>Instagram</span> */}
            </a>
            <a
              className="contactSocial facebook"
              href="https://www.facebook.com/kampuskres/"
            >
              <img src={facebook} alt="Facebook Logo" />
              {/* <span>Facebook</span> */}
            </a>
            <a
              className="contactSocial linkedin"
              href="https://www.linkedin.com/company/kamp%C3%BCs-kre%C5%9F"
            >
              <img src={linkedin} alt="LinkedIn Logo" />
              {/* <span>LinkedIn</span> */}
            </a>
            <a
              className="contactSocial youtube"
              href="https://www.youtube.com/channel/UCCCfHNQy0H8CFwmTIpOw_lw"
            >
              <img src={youtube} alt="YouTube Logo" />
              {/* <span>YouTube</span> */}
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}
