import React from "react";
import { Helmet } from "react-helmet";
import InfoForm from "../ad/components/InfoForm";

export default function InfoFormPage() {
  const [status, setStatus] = React.useState({
    error: false,
    success: false,
    description: null,
  });
  return (
    <React.Fragment>
      <Helmet>
        <title>Kampüs Kreş - Bilgi Talep Formu</title>
        <meta name="title" content="Kampüs Kreş - Bilgi Talep Formu" />
        <meta property="og:title" content="Kampüs Kreş - Bilgi Talep Formu" />
        <meta name="twitter:title" content="Kampüs Kreş - Bilgi Talep Formu" />
      </Helmet>
      <main
        style={{
          minHeight: "80svh",
          background: "#2b2365",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "20px",
        }}
      >
        <InfoForm
          className="infoForm purple"
          status={status}
          setStatus={setStatus}
        />
      </main>
    </React.Fragment>
  );
}
