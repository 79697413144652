import React from "react";
import { Helmet } from "react-helmet";

const page = [
  { pathName: "egitim-anlayisimiz", pageName: "Eğitim Anlayışımız" },
  function () {
    return (
      <div className="subPages_okulumuz-egitimAnlayisimiz_wrap">
        <Helmet>
          <title>Kampüs Kreş - Eğitim Anlayışımız</title>
          <meta name="title" content="Kampüs Kreş - Eğitim Anlayışımız" />
          <meta
            property="og:title"
            content="Kampüs Kreş - Eğitim Anlayışımız"
          />
          <meta
            name="twitter:title"
            content="Kampüs Kreş - Eğitim Anlayışımız"
          />
        </Helmet>
        <div className="subPages_okulumuz-egitimAnlayisimiz_card">
          <div className="subPages_okulumuz-egitimAnlayisimiz_firstColumn">
            <p>
              Kampüs Kreş eğitim müfredatı çocukların zihinsel, fiziksel, sosyal
              ve duygusal gelişimini desteklemeyi amaçlamaktadır.
            </p>
            <img
              alt="Çift Dilli Eğitim"
              src={require("../../../../assets/compressed/ciftdilliegitim.webp")}
            />
          </div>
          <p className="subPages_okulumuz-egitimAnlayisimiz_secondColumn">
            Programımız araştırma temelli olup, çocukların doğru cevaplarını
            ödüllendirmek yerine, onlara doğru sorular sormanın değerini
            öğretmeyi hedeflemektedir. Öğretmenler daha mücadeleci ve düşünce
            üretici açık uçlu sorular sorarak çocuklarda merak etmenin tadını
            çıkarmayı tercih etmektedirler. Çocukların gelişimleri farklı
            ortamlarda, özellikle oyun yoluyla gözlemlenmektedir. Sosyal
            becerilerin gelişimi ile birlikte, işbirliği, sorumluluk alma ve bir
            toplumda yaşamak için gerekli olan arkadaşlık becerileri kazanmaları
            desteklenmektedir. Öğretmenler sınıf ortamında farklılıklar
            oluşturmayı severler. Çocuklar için sürpriz olacak ve tartışma
            başlatacak "objeler" seçerler: Mutfak köşesine konan bir pizza
            kutusu, blok köşesine konan kağıt kalemler ya da çocukların sınıfa
            ilk girdiklerinde burunlarını harekete geçirecek aromatik kokular
            gibi
          </p>
          <p className="subPages_okulumuz-egitimAnlayisimiz_thirdColumn">
            Eğitim programlarımızda Reggio Emilia,Gems,Math Their Way,Orff
            çalışmaları ve Milli Eğitim Müfredatının farklı stratejileri
            kullanılmaktadır. Anaokulumuzda “Her çocuk özeldir!” ilkesinden yola
            çıkılarak öğrenci merkezli bir anlayış benimsenir. Kendine güvenen,
            özgür ve bağımsız kararlar alıp uygulayabilen, insani değerlere
            sahip, yaratıcı, girişken, etkili iletişim kurabilen, sorun
            çözebilen, bireyler yetiştirmek temel hedefimizdir. Bu hedefler
            doğrultusunda; TC. Milli Eğitim Bakanlığı Okul Öncesi Eğitimi
            Programı’nın yanı sıra High/Scope (Aktif Öğrenme), Değerler Eğitimi,
            GEMS (Fende ve Matematikte Büyük Araştırmalar) gibi modern eğitim
            yaklaşımlarından yararlanarak Çoklu Zekâ Kuramına uygun karma bir
            eğitim programı uygulanmaktadır. Her çocuğun potansiyelinin en üst
            seviyeye çıkabilmesi için farklı öğrenme aktiviteleri planlanır
          </p>
        </div>
        <div className="subPages_okulumuz-egitimAnlayisimiz_card">
          <div className="subPages_okulumuz-egitimAnlayisimiz_firstColumn">
            <div>
              <h1>Çoklu Zeka Kuramı</h1>
              <p>
                Geleneksel bakış açısına göre zekâ, doğuştan getirilen, IQ
                testleri ile belirlenen, sabit, değişmez, tekil bir yetidir.
              </p>
            </div>
            <img
              alt="Çoklu Zeka Kuramı"
              src={require("../../../../assets/compressed/ogrenmenin_8_bicimi.webp")}
            />
          </div>
          <p className="subPages_okulumuz-egitimAnlayisimiz_secondColumn">
            Oysa ABDli psikolog Howard Gardner, 1983 yılında zekâyı yeniden
            tanımlamıştır. Zekânın tek boyutlu olmadığını, her bireyin farklı
            derecelerde çeşitli zekâlara sahip olduğunu ortaya koymuştur. Buna
            göre, 8 farklı zekâ alanı tanımlamıştır ve her bireyde tüm zekâ
            alanlarının bulunduğunu, ancak her bireyde bazılarının çekinik,
            bazılarının baskın olduğunu iddia etmiştir. Ona göre, her biri
            geliştirilip güçlendirilebilir. Geleneksel yöntem, eğitim-öğretimde
            mantıksal/matematiksel ve sözel/dilsel zekâya odaklanmaktadır. Oysa
            bir konuyu öğretirken o konuyu öğretmek için farklı yollar da
            bulunmaktadır. Bir konuyu klasik yöntemlerle öğrenmede zorluk
            yaşayan bir çocukla karşılaşan bir öğretmen çoklu zekâyı bir çerçeve
            olarak kullanabilir. Her çocuğun olayları anlamlandırma, algılama ve
            öğrenme şekli birbirinden farklıdır.
          </p>
          <p className="subPages_okulumuz-egitimAnlayisimiz_thirdColumn">
            Örneğin, mantıksal zekâsı gelişmiş bir çocuk neden-sonuç ilişkisiyle
            öğrenirken, bedensel zekâsı gelişmiş bir çocuk dokunarak, görsel
            zekâsı gelişmiş bir çocuk ise resimler,grafikler ve video filmlerle
            daha iyi ve kolay öğrenir. Hedefimiz; her çocuk için, tüm zekâ
            alanlarının gelişim düzeyini derecelendirme ölçeği, gözlem ve
            anne-babalardan alınan bilgiler ışığında belirlemek, böylece her
            çocuğun bireysel farklılıklarını göz önünde bulunduran, bunlara
            uygun eğitim metotları içeren programlar hazırlamak ve tüm zekâ
            alanlarını geliştirmeye yönelik olanaklar sunmaktır.
          </p>
        </div>
        <div className="subPages_okulumuz-egitimAnlayisimiz_card">
          <div className="subPages_okulumuz-egitimAnlayisimiz_firstColumn">
            <div>
              <h1>High-Scope Programı</h1>
              <p>
                High/Scope Programı’nın dayandığı temel felsefe tüm duyuları
                kullanarak aktif olarak öğrenmedir.
              </p>
            </div>
            <img
              alt="High-Scope Programı"
              src={require("../../../../assets/compressed/etkin-öğrenme-kucuk.webp")}
            />
          </div>
          <p className="subPages_okulumuz-egitimAnlayisimiz_secondColumn">
            Bir öğrenme olayında tüm duyu organları ve vücut işin içine girdiği
            zaman öğrenmenin kalıcı ve gerçek öğrenme olduğuna inanılır.
            High/Scope yaklaşımında yer alan planla-yap-değerlendir süreci,
            programının en önemli kısmıdır ve bu süreçte öğrenciler öğrenmeye
            aktif olarak katılmaktadır. Çocukların oynayacakları oyunları
            planlamalarına, planlarını gerçekleştirmelerine ve daha sonra
            yaptıkları şeyler üzerinde düşünmelerine olanak tanır. Bu süreç
            çocukların özgüvenlerini ve bağımsızlıklarını destekleyen,
            sorumluluk alma ve zaman denetimini gerçekleştiren, özeleştiri
            becerisi kazandıran bir oyun oynama sürecidir.
          </p>
          <p className="subPages_okulumuz-egitimAnlayisimiz_thirdColumn">
            Bu eğitim yöntemi ile çocuklar;
            <ul>
              <li>
                Düşüncelerini ifade edebilme, yaratıcı düşünce geliştirebilme,
              </li>
              <li>Hayal gücünü kullanma ve dramatize edebilme,</li>
              <li>Problemlerini tanıma ve çözüm yeteneğini arttırabilme,</li>
              <li>Kişisel ilgi ve yeteneklerini keşfetme,</li>
              <li>
                Grup etkinliklerinde yetişkin ve diğer çocuklarla işbirliğine
                girebilme, liderliği paylaşabilme yeteneklerini kazanırlar.
              </li>
            </ul>
          </p>
        </div>
        <div className="subPages_okulumuz-egitimAnlayisimiz_card">
          <div className="subPages_okulumuz-egitimAnlayisimiz_firstColumn">
            <div>
              <h1>
                Gems Programı
                <br />
                (Fen ve Matematikte Büyük Buluşlar)
              </h1>
              <p>
                Okul öncesi yıllar çocukların bir bilim adamı gibi çevrelerini
                sürekli araştırdıkları, keşifler yaptıkları, doğal merak
                duygularının en yüksek olduğu, buna bağlı olarak sürekli sorular
                sordukları yıllardır.
              </p>
            </div>
            <img
              alt="Gems Programı"
              src={require("../../../../assets/compressed/shutterstock_158009765_kucuk.webp")}
            />
          </div>
          <p className="subPages_okulumuz-egitimAnlayisimiz_secondColumn">
            Çocuklar erken yaşlarda bilgiyi keşfetmeyi, bilginin somuttan soyuta
            gelişimini ve bir bilginin bir çok bilgiyle bağlantısını yaşayarak
            öğrenir. Gems programı, öğrencilere temel bilimsel kavram ve
            yöntemleri oyunlarla ve etkinliklerle basitten karmaşığa verirken
            hayal güçlerini de geliştirmeye çalışmaktadır. Gems Programı
            içerisinde yer alan Fen ve matematik etkinlikleriyle çocuklar;
            Gözlem, deney, araştırma, inceleme ve keşfetmeye yönelik bilimsel
            beceriler kazanırken, matematik etkinlikleri yoluyla da
            sınıflandırma, gruplama, eşleme, sıralama, ölçme, uzaysal konum gibi
            pek çok kavramı aktif olarak öğrenirler. Çocukların fen ve
            matematiğe ilgi duymaları sağlanarak bilimsel düşünmenin ilk
            temelleri atılır.
          </p>
          <p className="subPages_okulumuz-egitimAnlayisimiz_thirdColumn">
            Programın amacı;
            <ul>
              <li>Soran ve sorgulayan,</li>
              <li>Çok yönlü düşünme becerileri gelişmiş,</li>
              <li>
                Bilime karşı olumlu tavır geliştirmiş bireyler yetiştirmektir.
              </li>
            </ul>
          </p>
        </div>
        <div className="subPages_okulumuz-egitimAnlayisimiz_card">
          <div className="subPages_okulumuz-egitimAnlayisimiz_firstColumn">
            <div>
              <h1>Değerler Eğitim Programı</h1>
              <p>
                Okul öncesi dönemde çocuklar görerek, model alarak ve yaşayarak
                öğrenirler.
              </p>
            </div>
            <img
              alt="Değerler Eğitim Programı"
              src={require("../../../../assets/compressed/degerler.webp")}
            />
          </div>
          <p className="subPages_okulumuz-egitimAnlayisimiz_secondColumn">
            Öğrendiklerini uygular, deneyimleyerek pekiştirirler ve
            içselleştirirler. Çocuklarımızın kazanacakları her davranış;
            yaparak, çevresinden model alarak ve ortama aktif olarak katılım ile
            yerleşir. Karakter eğitiminin amacı, çocuklarımızın olumlu
            davranışlarını pekiştirip bu davranışları alışkanlık haline
            getirmelerini sağlayarak olumlu karakter özelliklerine sahip
            bireyler olarak yetişmelerine yardımcı olmaktır. Sağlam bir
            karakterin oluşumunda "çocuğa verilen değerin hissettirilmesi" en
            önemli adımlardan ilkidir. Çünkü olumlu davranışlar uygun zemin
            bulduğu zaman gelişir. Bu gelişim, davranışın karakter halini
            almasıyla devam eder. Bu bağlamda hem öğretmenlere, hem de
            anne-babalara özellikle model olma açısından önemli görevler düşer.
          </p>
          <p className="subPages_okulumuz-egitimAnlayisimiz_thirdColumn">
            Hedefimiz çocuklarımıza sınıf içerisinde etkinlikler, sınıf
            çalışmaları, oyunlar, hikayeler, drama çalışmaları ve örnek olay
            yöntemleriyle sorumluluk, dürüstlük, özgüven, sevgi, saygı,
            arkadaşlık gibi evrensel değerleri kavratmak ve bu değerleri
            davranışlarına yansıtabilmelerini sağlayarak onları sosyal-duygusal
            yönden desteklemektir.
          </p>
        </div>
        <div className="subPages_okulumuz-egitimAnlayisimiz_card">
          <div className="subPages_okulumuz-egitimAnlayisimiz_firstColumn">
            <div>
              <h1>Çift Dilli Eğitim ( Bilingual Education )</h1>
              <p>
                Okulumuzda, erken yaşta dil edinimini destekleyen{" "}
                <strong>Developmental Bilingual System</strong> uygulanmaktadır.
                Bu sistem, çocukların doğal yollarla ikinci bir dili edinmesini
                teşvik eder ve 2 yaş itibarıyla başlar.
              </p>
            </div>
            <img
              alt=" Çift Dilli Eğitim ( Bilingual Education )"
              src={require("../../../../assets/compressed/cift-new.webp")}
            />
          </div>
          <p className="subPages_okulumuz-egitimAnlayisimiz_secondColumn">
            Eğitim süreci,{" "}
            <strong>anadili İngilizce olan gerçek yabancılar</strong> eşliğinde
            yürütülerek çocukların dil gelişimini destekleyen gerçek yaşam
            etkileşimlerine dayandırılır. Bu sayede, çocuklar İngilizceyi sadece
            bir ders olarak değil, gündelik yaşamlarının doğal bir parçası
            olarak öğrenirler.
            <br />
            <strong>Sistemimizin Temel İlkeleri:</strong>
            <ul style={{ paddingLeft: "16px", lineHeight: "16px" }}>
              <li>
                <strong>
                  Türkçe eğitim müfredatının tamamen hedef dile transferi
                </strong>{" "}
                ile öğrencilerin her iki dili de aktif bir şekilde kullanmaları
                sağlanır.
              </li>
              <li>
                <strong>Sıkça Kullanılan Teknikler:</strong>
                <ul style={{ paddingLeft: "16px", lineHeight: "16px" }}>
                  <li>
                    <strong>
                      Communicative Approach (İletişimsel Yaklaşım):
                    </strong>{" "}
                    Çocukların dili doğal bağlamlarda kullanarak öğrenmesini
                    sağlar.
                  </li>
                  <li>
                    <strong>Natural Approach (Doğal Yaklaşım):</strong> Dil
                    ediniminin, tıpkı anadil öğrenimi gibi, doğal süreçler
                    içerisinde gerçekleşmesine yardımcı olur.
                  </li>
                </ul>
              </li>
            </ul>
          </p>
          <p className="subPages_okulumuz-egitimAnlayisimiz_thirdColumn">
            <strong>Her Yaş Grubu Dahil Olabilir</strong> Kreş programımız, her
            yaş grubundan öğrencilerin dil edinim sürecine katılımını destekler.
            Çocuklar, eğitim süreci boyunca farklı yaş gruplarına uygun olarak
            tasarlanmış dil etkileşimleriyle öğrenirler.
            <br /> <strong>Evrensel Terimlerin Öğrenimi</strong> Çocuklarımız,
            günlük yaşamlarında karşılaşacakları temel kavramları{" "}
            <strong>
              {" "}
              müzik, sanat, doğa, matematik, okuma-yazma, fen ve teknoloji
            </strong>
            gibi alanlarda öğrenirler. Böylece, iki dilde de akademik ve sosyal
            becerilerini geliştirerek, evrensel terminolojiye hâkim bireyler
            olarak yetişirler. Kampüsümüzün çift dilli eğitim anlayışı,
            çocuklarımızın global bir dünyaya hazırlanmasını sağlarken, onların
            bilişsel ve sosyal gelişimlerine de büyük katkı sunar.
          </p>
        </div>
      </div>
    );
  },
];

export default page;
