const { useEffect, useState } = require("react");
const { ChevronRightIcon, ChevronLeftIcon } = require("../../../assets/icons");
const { ReviewCard } = require("../../home/components/reviewCard");

export default function ReviewSection({ winWidth }) {
  const [slideRow, setSlideRow] = useState(0);

  useEffect(() => {
    setSlideRow(1);
  }, []);

  useEffect(() => {
    const sliderWrap = document.querySelector(".reviewsSlider");
    const slides = document.querySelector(".reviewsSlides");
    const slideChilds = slides.childNodes;

    const currentSlide = slideChilds[slideRow];

    currentSlide.classList.add("onDisplay");
    let nextSibling = currentSlide.nextSibling;
    nextSibling?.classList.add("next");
    let previousSibling = currentSlide.previousSibling;
    previousSibling?.classList.add("prev");

    let slideRightButton = document.querySelector(
      "#reviews-slidecontrol-right"
    );
    if (!nextSibling) {
      slideRightButton.style.display = "none";
    } else if (slideRightButton.style.display === "none") {
      slideRightButton.style.display = "flex";
    }

    let slideLeftButton = document.querySelector("#reviews-slidecontrol-left");
    if (!previousSibling) {
      slideLeftButton.style.display = "none";
    } else if (slideLeftButton.style.display === "none") {
      slideLeftButton.style.display = "flex";
    }

    if (nextSibling?.classList.value.includes("onDisplay")) {
      nextSibling.classList.remove("onDisplay");
    }

    if (previousSibling?.classList.value.includes("onDisplay")) {
      previousSibling.classList.remove("onDisplay");
    }

    if (currentSlide?.classList.value.includes("prev")) {
      currentSlide.classList.remove("prev");
    }

    if (currentSlide?.classList.value.includes("next")) {
      currentSlide.classList.remove("next");
    }

    slides.style.left = `${
      (sliderWrap.offsetWidth - currentSlide.offsetWidth) / 2 -
      slideRow *
        (currentSlide.offsetWidth +
          parseInt(getComputedStyle(slides).gap.replace(/[a-z]*/gi, "")))
    }px`;
  }, [slideRow, winWidth]);

  const ErkekAvatar = require("../../../assets/man.png");
  const KizAvatar = require("../../../assets/girl.png");

  return (
    <div id="page-reviews" className="homeContentBox brand">
      <h2
        id="reviews-section_title_1"
        className="homeContentBoxTitle camelcase white"
      >
        Mutlu velilerimiz
      </h2>
      <div className="reviewsSlider">
        <span
          onClick={() => setSlideRow((oldRow) => oldRow - 1)}
          id="reviews-slidecontrol-left"
        >
          <ChevronLeftIcon />
        </span>
        <span
          onClick={() => setSlideRow((oldRow) => oldRow + 1)}
          id="reviews-slidecontrol-right"
        >
          <ChevronRightIcon />
        </span>
        <div className="reviewsSlides">
          <ReviewCard avatar={KizAvatar} name="Pınar Dursun" date="2018">
            Ev sıcaklığında bir kreş. Her gün koşa koşa geldiği ve gitmek
            istemediği ikinci evi kızımızın. Biliyoruz ki burada kendini fark
            edecek ve en büyük önceliği kaliteli öğrenmek. Kampüslü olmak
            ayrıcalık ve daha 5 yaşındayken profesyonellikle tanışmaktır.
            Herkese teşekkürler.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="İlke Öndül" date="2018">
            Çocuğumdaki potansiyeli her alanda keşfedip açığa çıkardığınız ve
            daha da geliştirip, arttırdığınız için ve bunu alanında en iyi
            eğitimciler eşliğinde yaptığınız için sizlere minnettarız.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Özlem Özcan Akat" date="2018">
            Kampüs Kreş gibi okulları olan bir millerin istikbali emniyettedir.
            Her şey ama her şey için çok teşekkürler Kampüs Kreş Ailesi.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Neylan Pek" date="2018">
            Kampüs Kreş yönetimi olarak çok kurumsal ve profesyonel olduğunuzu
            düşünüyorum. Çocuğumu gönül rahatlığıyla gönderdiğimi
            söyleyebilirim. Öğretmenlerin yaklaşımı ve ilgisinin de gayet
            yerinde olduğunu gözlemliyorum. Teşekkür ederim böyle bir kurumu
            ülkemizde var ettiğiniz için.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Güldeste Varol" date="2018">
            Kampüs Kreş, eğitimi ile, öğretimi ile çocuklarımıza sunduğu
            olanaklar ile Türkiye'nin en iyi kreşi. Çocuklarımıza çocukluğunu
            doyasıya yaşatarak, eğlenerek öğrenme olanağı sunuyor. Kızım burada
            çok mutlu. Kızım burada çocukluğunu yaşayabiliyor. Emeği geçenlere
            sonsuz teşekkürler.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Semra Özgür" date="2018">
            Oğlumdaki olumlu yöndeki değişimi ben dahil tüm çevremiz fark etmiş
            durumda. Kampüs Kreş ile birlikte "Okul çok iyi geldi." cümlesini
            sıkça kullanıyor ve duyuyoruz. Her şeyden öte büyük istekle ve
            mutlulukla okula gidiyor.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Begüm Gözde Ertaş" date="2018">
            Eylül ayında başladığı ilk gün okula ağlayarak giden oğlum hasta
            olsa bile okula gitmek için sabırsızlanıyor. 6 ayda gerek sosyal
            gerek dil gelişimi, kas gelişiminde son derece gelişim gösterdi.
            Kampüs Kreş verdiğimiz en doğru kararlardan birisidir. Sınıf
            öğretmenlerimizin emeğine sağlık. Çok teşekkürler. İyi ki varsınız.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Beyhan Özalp" date="2018">
            Kampüs Kreş'i çok beğeniyorum. Keşke bende çocuk olsaydım dedirten
            bir okul. Okulun kalitesi her alanında belli oluyor. Sınıfımızdan ve
            öğretmenlerimizden çok memnunuz. İlgilerinden ötürü teşekkür ederiz.
          </ReviewCard>
          <ReviewCard avatar={ErkekAvatar} name="Selamet Yılmaz" date="2018">
            Kampüs Kreş olarak son derece çağdaş bir ortamda, etkin ve güler
            yüzlü personel ile beraber olmaktan memnuniyet duyuyoruz. Gönül
            rahatlığı ile çocuğumuzu teslim ediyoruz ve gelişimini izliyoruz.
            Başarılarınızın devamını diliyoruz.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Dilşad Bingöl" date="2017">
            Öncelikle okulumuzun mimarı yapısını ve donanımını çocuğumun
            gelişimi için yeterli buluyorum. Öğretmenlerimizin çocuğumuza ve
            bize karşı göstermiş oldukları iletişim dilini beğeniyorum. Daima
            bizimle işbirliği içinde ve destekleyiciler. Çocuğumuza karşı
            şefkatli ve özveriler.
          </ReviewCard>
          <ReviewCard avatar={ErkekAvatar} name="Gökhan Yıldız" date="2017">
            Çocuğumu gönül rahatlığı ile emanet ettiğim donanım ve fiziki
            imkanlarını çok beğendiğim bir okul. Tüm personelin görevini
            titizlikle yaptığı ve mesleklerinin erbabı kişiler olduğu her
            hallerinden belli.
          </ReviewCard>
          <ReviewCard avatar={KizAvatar} name="Özlem Özcan Akat" date="2017">
            Çocuğumu güvenle emanet edebildiğim, çocuğuma anne şefkatiyle
            yaklaşan öğretmenlerin bir arada bulunduğu, bir çocuğun
            isteyebileceği tüm şeyleri bünyesinde barındıran, her geldiğimde
            keşke çocuk olsam dediğim bir okul Kampüs Kreş. Emeği geçen herkse
            sonsuz teşekkür.
          </ReviewCard>
          <div className="reviewCard reviewAdvert">
            <div className="avatarGroup">
              <div className="advertSmallAvatar">
                <img alt="kiz avatar" src={KizAvatar} />
              </div>
              <div className="advertSmallAvatar">
                <img alt="erkek avatar" src={ErkekAvatar} />
              </div>
            </div>
            <h3>Tüm Veli Görüşlerini Görüntüleyin</h3>
            <a href="/okulumuz/veli-gorusleri">Veli Görüşleri</a>
          </div>
        </div>
      </div>
    </div>
  );
}
