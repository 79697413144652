"use client";

import React, { useState } from "react";

import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";

import "../../newForm/inputs/CheckBoxInput/styles.checkboxinput.css";

export function CheckBoxInputInfo({ id, label, value, popUpPage, ...props }) {
  const [modal, setModal] = useState(false);
  const [bottomPage, setBottomPage] = React.useState(false);

  const dialogRef = React.useRef(null);

  function handleScroll(e) {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - 60 < e.target.clientHeight;
    if (bottom) {
      setBottomPage(true);
    } else if (bottomPage) {
      setBottomPage(false);
    }
  }

  function handleCloseDialog() {
    dialogRef.current?.close();
    setModal(false);
    document.body.style.overflowY = "scroll";
  }

  React.useEffect(() => {
    if (modal) {
      setTimeout(() => {
        const dummy = dialogRef.current.querySelector("#scrolldummy");
        dummy.scrollIntoView({ behavior: "smooth" });
      }, 0);
    }
  }, [modal]);

  return (
    <div {...props} className="input_checkbox">
      <dialog ref={dialogRef} className="checkbox_modal" open={modal}>
        <div className="dialogPage" onScroll={(e) => handleScroll(e)}>
          {popUpPage &&
            popUpPage({
              size: "small",
            })}
        </div>
        <div data-show={!bottomPage} className="dialogInfo">
          <InfoIcon />
          Onaylamak için metnin sonuna geliniz
        </div>
        <div className="dialogButtonWrap">
          <button onClick={handleCloseDialog} type="button">
            Vazgeç
          </button>
          <button
            onClick={() => {
              handleCloseDialog();
              value.set(true); // set boolean to true
            }}
            disabled={!bottomPage}
            type="button"
          >
            Onaylıyorum
          </button>
        </div>
      </dialog>
      <input
        id={id}
        checked={value.get}
        onClick={() => {
          if (value.get === true) {
            value.set(false);
          } else {
            if (popUpPage) {
              dialogRef.current?.showModal();
              setModal(true);
              document.body.style.overflowY = "hidden";
            } else {
              value.set(true);
            }
          }
        }}
        type="checkbox"
      />
      <label htmlFor={id}>
        {label}
        {props.required && <span> *</span>}
      </label>
    </div>
  );
}
