import { Map } from "../../home/components/map";
import InfoForm from "./InfoForm";

import {
  PhoneFilledIcon,
  PinIcon,
  MailIcon,
  WhatsAppIcon,
} from "../../../assets/icons/index.js";

import instagram from "../../../assets/instagram.png";
import youtube from "../../../assets/youtube.png";
import facebook from "../../../assets/facebook.png";
import linkedin from "../../../assets/linkedIn.png";

export default function ContactSection({
  formFirstMobile,
  hideTitle,
  status,
  setStatus,
  ...props
}) {
  return (
    <div className="homeContentBox blurple" {...props}>
      {!hideTitle && (
        <h2 className="homeContentBoxTitle camelcase white">İletişim</h2>
      )}
      <div className="contactGroups">
        <div
          data-formfirstmobile={formFirstMobile ? true : false}
          className="contactBigRowGroup"
        >
          <Map />
          <InfoForm
            className="infoForm purple"
            status={status}
            setStatus={setStatus}
          />
        </div>
        <div className="contactRowWrap">
          <a href="tel:+90 216 420 75 75" className="contactItem">
            <PhoneFilledIcon />
            <span>+90 216 420 75 75</span>
          </a>
          <a href="mailto:info@kampuskres.com.tr" className="contactItem">
            <MailIcon />
            <span>info@kampuskres.com.tr</span>
          </a>
          <div className="contactItem">
            <PinIcon />
            <span>Dudullu OSB, 5. Cadde, No:6 Ümraniye / İSTANBUL</span>
          </div>
        </div>
        <div className="contactSocialWrap">
          <a
            className="contactSocial instagram"
            href="https://www.instagram.com/kampuskres/"
          >
            <img src={instagram} alt="Instagram Logo" />
            {/* <span>Instagram</span> */}
          </a>
          <a
            className="contactSocial facebook"
            href="https://www.facebook.com/kampuskres/"
          >
            <img src={facebook} alt="Facebook Logo" />
            {/* <span>Facebook</span> */}
          </a>
          <a
            className="contactSocial linkedin"
            href="https://www.linkedin.com/company/kamp%C3%BCs-kre%C5%9F"
          >
            <img src={linkedin} alt="LinkedIn Logo" />
            {/* <span>LinkedIn</span> */}
          </a>
          <a
            className="contactSocial youtube"
            href="https://www.youtube.com/channel/UCCCfHNQy0H8CFwmTIpOw_lw"
          >
            <img src={youtube} alt="YouTube Logo" />
            {/* <span>YouTube</span> */}
          </a>
          <a
            className="contactSocial wp"
            href="https://wa.me/902164207575?text=Merhaba"
          >
            <WhatsAppIcon />
            {/* <span>WhatsApp</span> */}
          </a>
        </div>
      </div>
    </div>
  );
}
