import { useEffect, useState } from "react";
import {
  MobileSmallGalleryImage,
  SmallGalleryImage,
} from "../../home/components/gallerySmallImage";
import { Link } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../assets/icons";

export default function GallerySection({ winWidth }) {
  let imageStock = [
    {
      image:
        "https://api.kampuskres.com.tr/images/image/5853-9318-7376-8706.webp",
      displayName: "none",
    },
    {
      image:
        "https://api.kampuskres.com.tr/images/image/9204-7022-6405-5401.webp",
      displayName: "none",
    },

    {
      image:
        "https://api.kampuskres.com.tr/images/image/2613-8875-2408-3386.webp",
      displayName: "none",
      backgroundPosition: "50% 100%",
    },
    {
      image:
        "https://api.kampuskres.com.tr/images/image/3364-2750-1082-3490.webp",
      displayName: "none",
    },
    {
      image:
        "https://api.kampuskres.com.tr/images/image/5363-2893-8318-7065.webp",
      displayName: "none",
    },
    {
      image:
        "https://api.kampuskres.com.tr/images/image/3557-0942-5744-6708.webp",
      displayName: "none",
    },
    {
      image:
        "https://api.kampuskres.com.tr/images/image/1457-0692-6102-8865.webp",
      displayName: "none",
      backgroundPosition: "50% 25%",
    },
    {
      image:
        "https://api.kampuskres.com.tr/images/image/4320-1684-2676-0596.webp",
      displayName: "none",
    },
    {
      image:
        "https://api.kampuskres.com.tr/images/image/9417-2380-7323-3795.webp",
      displayName: "none",
    },
    {
      image:
        "https://api.kampuskres.com.tr/images/image/4578-1518-8054-2666.webp",
      displayName: "none",
    },
    {
      image:
        "https://api.kampuskres.com.tr/images/image/6135-9885-5542-5205.webp",
      displayName: "none",
    },
    {
      image:
        "https://api.kampuskres.com.tr/images/image/3210-5201-8033-1142.webp",
      displayName: "none",
    },
  ];

  const [selectedImage, setSelectedImage] = useState(imageStock[0]);

  const [slideRow, setSlideRow] = useState(0);

  useEffect(() => {
    setSlideRow(1);
  }, []);

  useEffect(() => {
    if (winWidth < 1115) {
      const sliderWrap = document.querySelector(".galleryMobileSlider");
      const slides = document.querySelector(".galleryMobileSlides");
      const slideChilds = slides.childNodes;

      const currentSlide = slideChilds[slideRow];

      currentSlide.classList.add("onDisplay");
      let nextSibling = currentSlide.nextSibling;
      nextSibling?.classList.add("next");
      let previousSibling = currentSlide.previousSibling;
      previousSibling?.classList.add("prev");

      let slideRightButton = document.querySelector(
        "#gallery-slidecontrol-right"
      );
      if (!nextSibling) {
        slideRightButton.style.display = "none";
      } else if (slideRightButton.style.display === "none") {
        slideRightButton.style.display = "flex";
      }

      let slideLeftButton = document.querySelector(
        "#gallery-slidecontrol-left"
      );
      if (!previousSibling) {
        slideLeftButton.style.display = "none";
      } else if (slideLeftButton.style.display === "none") {
        slideLeftButton.style.display = "flex";
      }

      if (nextSibling?.classList.value.includes("onDisplay")) {
        nextSibling.classList.remove("onDisplay");
      }

      if (previousSibling?.classList.value.includes("onDisplay")) {
        previousSibling.classList.remove("onDisplay");
      }

      if (currentSlide?.classList.value.includes("prev")) {
        currentSlide.classList.remove("prev");
      }

      if (currentSlide?.classList.value.includes("next")) {
        currentSlide.classList.remove("next");
      }

      setSelectedImage(imageStock[slideRow]);

      slides.style.left = `${
        (sliderWrap.offsetWidth - currentSlide.offsetWidth) / 2 -
        slideRow *
          (currentSlide.offsetWidth +
            parseInt(getComputedStyle(slides).gap.replace(/[a-z]*/gi, "")))
      }px`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideRow, winWidth]);

  let displayImageLinear =
    "linear-gradient(360deg, rgba(0, 0, 0, 0.6) 15%, rgba(0, 0, 0, 0) 25%)";

  return (
    <div className="homeContentBox brandBlue">
      <h2 className="homeContentBoxTitle white">Kreşten kareler</h2>
      {winWidth > 1115 ? (
        <div className="galleryContentWrap">
          <div
            style={{
              backgroundPosition: selectedImage.backgroundPosition || "50% 50%",
              backgroundImage: `${
                selectedImage.displayName !== "none"
                  ? `${displayImageLinear}, `
                  : ""
              }url(${selectedImage.image})`,
            }}
            className="galleryDisplayImage"
          >
            {selectedImage.displayName !== "none"
              ? selectedImage.displayName
              : ""}
          </div>
          <div className="galleryImageGridAndButtonWrap">
            <div className="galleryImageGrid">
              <SmallGalleryImage
                onClick={() => setSelectedImage(imageStock[0])}
                selectedImage={selectedImage}
                image={imageStock[0].image}
              />
              <SmallGalleryImage
                onClick={() => setSelectedImage(imageStock[1])}
                selectedImage={selectedImage}
                image={imageStock[1].image}
              />
              <SmallGalleryImage
                onClick={() => setSelectedImage(imageStock[2])}
                selectedImage={selectedImage}
                image={imageStock[2].image}
              />
              <SmallGalleryImage
                onClick={() => setSelectedImage(imageStock[3])}
                selectedImage={selectedImage}
                image={imageStock[3].image}
              />
              <SmallGalleryImage
                onClick={() => setSelectedImage(imageStock[4])}
                selectedImage={selectedImage}
                image={imageStock[4].image}
              />
              <SmallGalleryImage
                onClick={() => setSelectedImage(imageStock[5])}
                selectedImage={selectedImage}
                image={imageStock[5].image}
              />
              <SmallGalleryImage
                onClick={() => setSelectedImage(imageStock[6])}
                selectedImage={selectedImage}
                image={imageStock[6].image}
              />
              <SmallGalleryImage
                onClick={() => setSelectedImage(imageStock[7])}
                selectedImage={selectedImage}
                image={imageStock[7].image}
              />
              <SmallGalleryImage
                onClick={() => setSelectedImage(imageStock[8])}
                selectedImage={selectedImage}
                image={imageStock[8].image}
              />
              <SmallGalleryImage
                onClick={() => setSelectedImage(imageStock[9])}
                selectedImage={selectedImage}
                image={imageStock[9].image}
              />
              <SmallGalleryImage
                onClick={() => setSelectedImage(imageStock[10])}
                selectedImage={selectedImage}
                image={imageStock[10].image}
              />
              <SmallGalleryImage
                onClick={() => setSelectedImage(imageStock[11])}
                selectedImage={selectedImage}
                image={imageStock[11].image}
              />
            </div>
            <Link to="/galeri" className="button whiteBack blueFront but-4324">
              Tüm Fotoğrafları Görüntüle
            </Link>
          </div>
        </div>
      ) : (
        <div className="galleryContentWrap">
          <div
            style={{
              backgroundPosition: selectedImage.backgroundPosition || "50% 50%",
              backgroundImage: `${
                selectedImage.displayName !== "none"
                  ? `${displayImageLinear}, `
                  : ""
              }url(${selectedImage.image})`,
            }}
            className="galleryDisplayImage"
          >
            {selectedImage.displayName !== "none"
              ? selectedImage.displayName
              : ""}
          </div>
          <div className="galleryMobileSlider">
            <span
              onClick={() => setSlideRow((oldRow) => oldRow - 1)}
              id="gallery-slidecontrol-left"
            >
              <ChevronLeftIcon />
            </span>
            <span
              onClick={() => setSlideRow((oldRow) => oldRow + 1)}
              id="gallery-slidecontrol-right"
            >
              <ChevronRightIcon />
            </span>
            <div className="galleryMobileSlides">
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[0].image}
              />
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[1].image}
              />
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[2].image}
              />
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[3].image}
              />
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[4].image}
              />
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[5].image}
              />
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[6].image}
              />
              <MobileSmallGalleryImage
                selectedImage={selectedImage}
                image={imageStock[7].image}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
