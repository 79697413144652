import React from "react";
import { Helmet } from "react-helmet";

const headline = "2024-2025 Eğitim Öğretim Yılı Akademik Takvimi";
const calendar = [
  "2024-2025  Eğitim–Öğretim Yılının Başlaması&&&2 Eylül 2024 Pazartesi",
  "Oryantasyon Çalışmaları&&&2 Eylül 2024-27 Eylül 2024",
  "Genel Veli Toplantısı&&&7 Eylül 2024 Cumartesi",
  "4 ve 5 yaş sınıfları Ebeveyn Katılımlı Kampüs Kreş Günü&&&19 Ekim 2024 Cumartesi",
  "Kampüs Kreş Günü - Ebeveyn Katılımlı Kreş Bebekleri&&&19 Ekim 2024 Cumartesi",
  "Cumhuriyet Bayramı Töreni&&&28 Ekim 2024 Pazartesi günü yapılacaktır. Okulumuz 13:00’a kadar açık olacaktır.",
  "10 Kasım Atatürk’ü Anma Günü&&&8 Kasım 2024 Cuma günü yapılacaktır.",
  "1.Dönem Bireysel Sınıf Öğretmeni/Veli Görüşmesi&&&30 Kasım 2024 Cumartesi günü. Okulda Yüz Yüze yapılacaktır.",
  "Yılbaşı Kutlama&&&30 Aralık 2024 Pazartesi günü",
  "I.Dönem Karne Partisi&&&17 Ocak 2025 Cuma günü",
  "I.Dönem Gelişim Raporları&&&13-17 Ocak 2025 haftası paylaşılır.",
  "Sömestr Tatili&&&20-24 Ocak 2025 haftası okulumuz kapalıdır.",
  "II.Dönem Başlangıç&&&27 Ocak 2025 Pazartesi günü",
  "2 ve 3 Yaş Ebeveyn Katılımlı Kampüs Kreş Günü&&&1 Mart 2025 Cumartesi.",
  "Kodlama Atölyesi Açık Ders&&&26 Mart 2025 Çarşamba günü okulda Yüz Yüze yapılacaktır.",
  "Ramazan Bayramı Tatili&&&29 Mart 2025 ve 1 Nisan 2025 tarihlerinde okulumuz kapalıdır.",
  "II. Dönem Bireysel Sınıf öğretmeni/Veli Görüşmesi&&&12 Nisan 2025 Cumartesi okulda Yüz Yüze yapılacaktır.",
  "23 Nisan Ulusal Egemenlik ve Çocuk Bayramı&&&22 Nisan 2025 Salı günü okulumuzda törenle kutlanacaktır. 23 Nisan 2025 Çarşamba günü okulumuz tatil olacaktır.",
  "1 Mayıs Emek ve Dayanışma Günü&&&Resmi Tatil Dolayısı ile okulumuz kapalıdır.",
  "3-4-5 Yaşlar Portfolyo Sunum Etkinliği&&&10 Mayıs 2025 Cumartesi günü",
  "19 Mayıs Atatürk’ü Anma, Gençlik ve Spor Bayramı&&&Resmi Tatil Dolayısı ile okulumuz kapalıdır.",
  "Kurban Bayramı Tatili&&&5 Haziran 2025 Perşembe günü okulumuz 13:00’a kadar açık olacaktır. 6 Haziran-9 Haziran 2025 tarihleri arasında Resmi Tatil dolayısı ile okulumuz kapalı olacaktır.",
  "Hazırlık Sınıfı Mezuniyet&&&21 Haziran 2025 Cumartesi günü Öğleden sonra yapılacaktır.",
  "II. Dönem Karne Partisi&&&20 Haziran 2025 Cuma günü",
  "II.Dönem Gelişim Raporları&&&16-20 Haziran 2025 Haftası yüklenecektir.",
  "2024-2025 Eğitim-Öğretim Döneminin Son Günü&&&27 Haziran 2025 Cuma",
  "Yaz Okulu 1.Kur (2 haftalık periyot)&&&30 Haziran-11 Temmuz 2025",
  "Yaz Tatili (Okulun kapalı olduğu dönem)&&&14 Temmuz 2025 – 25 Temmuz 2025",
  "Yaz Okulu 2.Kur (2 haftalık periyot)&&&28 Temmuz-8 Ağustos 2025",
  "Yaz Okulu 3.Kur (2 haftalık periyot)&&&11 Ağustos-22 Ağustos 2025",
  "2025-2026 Eğitim Öğretim Dönemi&&&01.09.2025 Pazartesi başlayacaktır.",
];

const page = [
  { pathName: "akademik-takvim", pageName: "Akademik Takvim" },
  function () {
    return (
      <div className="tableWithNoteWrap">
        <Helmet>
          <title>Kampüs Kreş - Akademik Takvim</title>
          <meta name="title" content="Kampüs Kreş - Akademik Takvim" />
          <meta property="og:title" content="Kampüs Kreş - Akademik Takvim" />
          <meta name="twitter:title" content="Kampüs Kreş - Akademik Takvim" />
        </Helmet>
        <table className="table">
          <tr>
            <th colSpan={2}>{headline}</th>
          </tr>
          {calendar.map((v) => {
            const args = v.split("&&&");

            return (
              <tr>
                <td>{args[0]}</td>
                <td>{args[1]}</td>
              </tr>
            );
          })}
        </table>
        <p>
          NOT: 25-29 Ağustos 2025 tarihleri arasında okulumuz kapalı olacaktır.
          <br />
          <br />
          NOT: Sağlık Bakanlığı’nın vereceği kararlar doğrultusunda akademik
          takvimde yer alan tarihler ve toplantıların online ya da yüz yüze olma
          durumu değişkenlik gösterebilir.
        </p>
      </div>
    );
  },
];

export default page;
