import React from "react";

import "../styles.home.content.css";

export function SmallGalleryImage(props) {
  let smallImageLinear =
    "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))";

  let style;
  if (props.id) {
    style = {
      backgroundImage: `${
        props.selectedImage._id === props.id ? "" : `${smallImageLinear},`
      }url(${props.image})`,
    };
  } else {
    style = {
      backgroundImage: `${
        props.selectedImage.image === props.image ? "" : `${smallImageLinear},`
      }url(${props.image})`,
    };
  }

  let className;
  if (props.id) {
    className = props.selectedImage._id === props.id ? "onDisplay" : "";
  } else {
    className = props.selectedImage.image === props.image ? "onDisplay" : "";
  }

  return (
    <button className={className} onClick={props.onClick} style={style}>
      &nbsp;
    </button>
  );
}

export function MobileSmallGalleryImage(props) {
  let className;
  if (props.id) {
    className =
      props.selectedImage._id === props.id ? "box-4324 onDisplay" : "box-4324";
  } else {
    className =
      props.selectedImage.image === props.image
        ? "box-4324 onDisplay"
        : "box-4324";
  }

  return (
    <div
      className={className}
      style={{ backgroundImage: `url(${props.image})` }}
    >
      &nbsp;
    </div>
  );
}
