import React from "react";

export function AydinlatmaMetni({ size }) {
  const doc2tab1 = require("./assets/doc2tab1.png");
  const doc2tab2 = require("./assets/doc2tab2.png");
  const doc2tab3 = require("./assets/doc2tab2.png");

  return (
    <article className={`document${size ? ` ${size}` : ""}`}>
      <div id="scrolldummy"></div>
      <header>
        İSTANBUL DUDULLU ORGANİZE SANAYİ BÖLGESİ KAMPÜS KREŞ VE GÜNDÜZ BAKIMEVİ
        <br />
        6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU
        <br />
        ÖN KAYIT İŞLEMLERİNE YÖNELİK AYDINLATMA METNİ
      </header>
      <div className="documentInner">
        <div className="documentColumn">
          <div>
            İstanbul Dudullu Organize Sanayi Bölgesi Kampüs Kreş ve Gündüz
            Bakımevi (“<strong>Kampüs Kreş</strong>”), ön kayıt işlemlerinden
            kaynaklı faaliyetlerini yürütebilmek ve kayıt olacak öğrenciyi
            tanıyabilmek adına birtakım kişisel veri işlemektedir. 6698 sayılı
            Kişisel Verilerin Korunması Kanunu (“<strong>KVKK”</strong>)
            kapsamında ilgili kişiler; kişisel verilerin işlenmesi, üçüncü
            kişilere aktarılması, kişisel veri toplamanın yöntemleri ve hukuki
            sebepleri ile KVKK’da yer alan hakları konularında detaylı bilgiye
            işbu aydınlatma metninden ulaşabilirler.
          </div>
        </div>
        <div className="documentColumn">
          <h3>
            A. İşlenen Kişisel Veriler, Veri İşleme Amacı, Toplama Yöntemi ve
            İşlemenin Hukuki Sebebi
          </h3>
          <div>
            Kişisel veri, kimliği belirli veya belirlenebilir gerçek kişiye ait
            her türlü bilgiyi ifade etmektedir. Bu kapsamda isim, soy isim,
            telefon numarası, e-posta adresi, eğitim durumu, doğum tarihi gibi
            kişiyi tanımlayan tüm bilgiler kişisel veridir. Kişisel verilerin
            işlenmesi ise; bu tür bilgilerin elde edilmesi, incelenmesi,
            kaydedilmesi, açıklanması, kullanılması gibi veriler üzerinde
            gerçekleştirilen her türlü işlemi ifade etmektedir. Kampüs Kreş,
            gerçekleştirmiş olduğu faaliyetlerde kişisel verileri KVKK’da yer
            alan veri işleme şartlarına (hukuki sebep) bağlı kalarak
            işlemektedir.
          </div>
        </div>
        <div className="documentColumn">
          <div>
            <strong>Kampüs Kreş’in</strong> veri işleme esnasında dayandığı
            hukuki sebepler şunlardır:
          </div>
          <ul>
            <li>
              Açık rızanın varlığı (Özel nitelikli kişisel veriler için “m.6/2”,
              Özel nitelikli olmayan kişisel veriler için “m.5/1”).
            </li>
            <li>
              Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
              olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
              işlenmesinin gerekli olması (“m.5/2-c”).
            </li>
            <li>
              İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
              kaydıyla, veri sorumlusunun meşru menfaatleri için veri
              işlenmesinin zorunlu olması (“m.5/2-f”).
            </li>
          </ul>
        </div>
        <div className="documentColumn">
          <img src={doc2tab1} alt="document data table"></img>
        </div>
        <div className="documentColumn">
          <img src={doc2tab2} alt="document data table"></img>
        </div>
        <div className="documentColumn">
          <h3>B. Kişisel Verilerin Aktarılması</h3>
          <div>
            İşlenen kişisel verileriniz yukarıdaki amaçlarla ve KVKK’nın
            belirlediği koşullar çerçevesinde aktarılabilmektedir:
          </div>
          <img src={doc2tab3} alt="document data table"></img>
        </div>
        <div className="documentColumn">
          <h3>C. İlgili Kişinin Hakları</h3>
          <div>
            Herkes, KVKK’nın 11. maddesi uyarınca aşağıdaki haklara sahiptir;
          </div>
          <ul className="numberList">
            <li>Kişisel verisinin işlenip işlenmediğini öğrenme,</li>
            <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
            <li>
              Kişisel verilerin işlenme amacını ve bunların amacına uygun
              kullanılıp kullanılmadığını öğrenme,
            </li>
            <li>
              Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
              kişileri bilme,
            </li>
            <li>
              Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
              bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
              kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
              isteme,
            </li>
            <li>
              KVKK ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş
              olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
              kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini
              isteme ve bu kapsamda yapılan işlemin kişisel verilerin
              aktarıldığı üçüncü kişilere bildirilmesini isteme,
            </li>
            <li>
              İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
              edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
              çıkmasına itiraz etme,
            </li>
            <li>
              Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
              uğraması hâlinde zararın giderilmesini talep etme.
            </li>
          </ul>
        </div>
        <div className="documentColumn">
          <div>
            Herkes bu haklarına ilişkin taleplerini Veri Sorumlusuna Başvuru
            Usul ve Esasları Hakkında Tebliğ’e uygun bir şekilde tarafımıza
            iletebilir. Talepler en kısa sürede ve nihayetinde en geç otuz (30)
            gün içerisinde ücretsiz olarak değerlendirilip karara bağlanacaktır.
            Değerlendirme ve karar verme işleminin ayrıca bir maliyeti
            gerektirmesi durumunda Kişisel Verileri Koruma Kurulu tarafından
            belirlenen tarifedeki ücret esas alınacaktır.
          </div>
          <h3>D. Veri Sorumlusunun Kimliği</h3>
          <div>
            <strong>Kampüs Kreş</strong> olarak yürüttüğümüz faaliyetlerimizde
            işlemiş olduğumuz kişisel veriler ile ilgili olarak{" "}
            <strong>KVKK</strong> çerçevesinde veri sorumlusu olarak hareket
            etmekteyiz.
          </div>
        </div>
        <div className="documentColumn">
          Kimlik ve iletişim bilgilerimiz aşağıda yer almaktadır.
          <div>
            <strong>Veri Sorumlusu:</strong> Dudullu Organize Sanayi Bölgesi
            <br />
            <strong>Mersis No:</strong> 0481009824900001
            <br />
            <strong>İnternet Adresi:</strong>{" "}
            <a href="http://www.idosb.org.tr/" rel="noreferrer" target="_blank">
              http://www.idosb.org.tr/
            </a>
            <br />
            <strong>E-Posta Adresi:</strong>{" "}
            <a href="mailto:kvkk@idosb.org.tr">kvkk@idosb.org.tr</a>
            <br />
            <strong>Adres:</strong> İstanbul Dudullu Organize Sanayi Bölgesi
            3.Cad. No:18/2 Ümraniye/İstanbul
            <br />
            <strong>Kep Adresi:</strong>{" "}
            <a href="mailto:dudulluorganize@hs01.kep.tr">
              dudulluorganize@hs01.kep.tr
            </a>
          </div>
        </div>
      </div>
    </article>
  );
}
