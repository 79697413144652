import React from "react";

import "./styles.admin.css";
import { Link } from "react-router-dom";

import {
  DocumentsIcon,
  EditIcon,
  ImageIcon,
  LockedIcon,
  MegaphoneIcon,
} from "../../../assets/icons";
import { Helmet } from "react-helmet";

export function AdminPage({ params, adminType }) {
  return (
    <div className="actionWrap">
      <Helmet>
        <title>Kampüs Kreş - Admin</title>
        <meta name="title" content="Kampüs Kreş - Admin" />
        <meta property="og:title" content="Kampüs Kreş - Admin" />
        <meta name="twitter:title" content="Kampüs Kreş - Admin" />
      </Helmet>
      {adminType === "ALP" && (
        <Link to={`/admin/form/edit${params}`} className="adminAction">
          <EditIcon />
          <h1>Form'u Düzenle</h1>
        </Link>
      )}
      <Link to={`/admin/forms${params}`} className="adminAction">
        <DocumentsIcon style={{ stroke: "black" }} />
        <h1>Formları Görüntüle</h1>
      </Link>
      <Link to={`/admin/infoForms${params}`} className="adminAction">
        <DocumentsIcon style={{ stroke: "black" }} />
        <h1>Bilgi Formlarını Görüntüle</h1>
      </Link>
      {(adminType === "KURUMSAL" || adminType === "ALP") && (
        <React.Fragment>
          <Link to={`/admin/gallery${params}`} className="adminAction">
            <ImageIcon className="imageIconAdmin" />
            <h1>Galeriyi Düzenle</h1>
          </Link>
          <Link to={`/admin/announcements${params}`} className="adminAction">
            <MegaphoneIcon className="imageIconAdmin" />
            <h1>Duyuruları Düzenle</h1>
          </Link>
        </React.Fragment>
      )}
      {adminType === "ALP" && (
        <Link to={`/admin/forms${params}`} className="adminAction">
          <LockedIcon />
          <h1>Kontenjanları Düzenle</h1>
        </Link>
      )}
    </div>
  );
}
